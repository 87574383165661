import React, { useState } from 'react';
import './App.css';
import PaymentForm from './PaymentForm';
import image from './header-image.png'; // Update with the correct path
import ThankYouComponent from './Succesfull';



function Parent() {
const [name, setName] = useState('');
const [frequency, setFrequency] = useState('Maandeliks');
const [amount, setAmount] = useState(100000); // Default amount in cents
const [paymentStatus, setPaymentStatus] = useState('');

  if (paymentStatus === 'success') {
    return <ThankYouComponent 
        name={name} 
        frequency={frequency} 
        amount={amount} 
        onClose={() => {
            setPaymentStatus('');
            window.location.href = "https://studenteplein.co.za";
        }}
    />;
}
    return (
        <div className="app">
          <div className="row row-1">
            <img src={image} alt="Combined Image" className="full-width-image" />
          </div>
          <div className="row row-2">
            <div className="text-container-1">
              <h2>Meer Afrikaans op Stellenbosch</h2>
              <h3>StudentePlein skep alternatiewe tuistes vir Afrikaanse studente en veg teen taalvergrype in klasse en koshuise.</h3>
            </div>
            <div className="form-wrapper">
              <PaymentForm 
              name={name}
              amount={amount}
              frequency={frequency}
              setName={setName}
              setAmount={setAmount}
              setFrequency={setFrequency}
              setPaymentStatus={setPaymentStatus}
              />
            </div>
          </div>
          <div className="row row-3">
            <div className="text-container-2">
              <h3>StudentePlein is ‘n nie-winsgewende organisasie wat deur oud-Maties gestig is. Jou skenking sal bydra tot ‘n florerende gemeenskap van Afrikaanse studente op Stellenbosch.</h3>
              <p>StudentePlein het oor die afgelope vier jaar belangrike mylpale behaal. Ons kon ‘n alternatiewe Afrikaanse leersisteem vir studente in Stellenbosch oprig, voete gee vir ‘n presterende Afrikaanse debatvereniging, sosiale Afrikaans op Stellenbosch laat herleef, en nog baie meer. Ons beskerm ook met regsaksie ons lede teen taalvergrype. Ons bestuurspan werk voltyds om ‘n sterk Afrikaanse studentegemeenskap bymekaar te snoer en afgeronde afgestudeerdes te smee.</p> 
              <p>Ons beskou dit as ons plig om ‘n sterk, langtermyn alternatief tot ‘n verpolitiseerde en verengelsde Stellenbosch-kampus te bied. Ons kan dit aanhou doen met die finansiële steun van oud-Maties, ouers van Matie-studente en andere.</p> 
              <p className="bold-text">Jou mildadigheid stel ons in staat om aan honderde Afrikaanse studente studiehulp in Afrikaans te bied en ‘n vrye en voorspoedige studentelewe moontlik te maak.</p>
            </div>
           
          </div>
          <div className="footer-row">
            
            </div>
          
        </div>
      );
  }

export default Parent;
