import React, { useState, useEffect } from 'react';  // Added useEffect import
import { debounce } from 'lodash';  // Import debounce from lodash if you use it
import { PaystackButton } from 'react-paystack';
import logo from './logo.png'; // Update with the correct path
import paystackLogos from './paystack-logos.png'; // Update with the correct path
import PolicyModal from './PolicyModal';
import './PaymentForm.css';

const PaymentForm = ({ name, amount, frequency, paymentStatus, setName, setAmount, setFrequency, setPaymentStatus }) => {
  const publicKey = process.env.REACT_APP_PAYSTACK_PUBLIC_KEY;
  const serverURI = process.env.REACT_APP_SERVER_URI;
  
  const [lastname, setSurname] = useState('');
  const [email, setEmail] = useState('');
  const [termsAccept, setTermsAccept] = useState(false);
  const [inputValue, setInputValue] = useState(''); // State for the input field
  const [isEmailValid, setEmailValid] = useState(false);
  const [isPolicyModalOpen, setIsPolicyModalOpen] = useState(false);
  const [isScrolling, setIsScrolling] = useState(false);

  const callback_url = "https://studenteplein.co.za"

  const isFormValid = name !== '' && lastname !== '' && email !== '' && isEmailValid && termsAccept;

  const openPolicyModal = () => {
    setIsPolicyModalOpen(true);
  };

  const closePolicyModal = () => {
    setIsPolicyModalOpen(false);
  };

  const handleAmountSelect = (amountValue) => {
    setAmount(amountValue * 100); // Convert Rands to cents for processing
    setInputValue('');
  };

  const handleInputChange = (e) => {
    const value = e.target.value.replace(/[^0-9]+/g, '');
    handleAmountSelect(Number(value));
    setInputValue(e.target.value); // Update the input field state
  };

  const handleKeyDown = (event) => {
    if (!/[0-9]/.test(event.key) && !['Backspace', 'ArrowLeft', 'ArrowRight', 'Tab'].includes(event.key)) {
      event.preventDefault(); // Prevent the key press
    }
  };

  const [emailError, setEmailError] = useState('');

  const validateEmail = (inputEmail) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(inputEmail).toLowerCase());
  };

  const handleEmailChange = (event) => {
    const { value } = event.target;
    setEmail(value);
    if (validateEmail(value)) {
      setEmailValid(true);
      setEmailError('');  // Clear error message if the email is valid
    } else {
      setEmailError('Voer asseblief \'n geldige e-posadres in.');  // Set error message if the email is invalid
      setEmailValid(false);
    }
  };

  function debounce(func, wait, immediate) {
    let timeout;
    return function executedFunction() {
      const context = this;
      const args = arguments;
          
      const later = function() {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };
  
      const callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      
      if (callNow) func.apply(context, args);
    };
  };

  useEffect(() => {
    const handleScrollStart = () => {
      setIsScrolling(true);
    };
  
    const handleScrollStop = () => {
      setTimeout(() => {
        setIsScrolling(false);
      }, 200);
    };
  
    window.addEventListener('scroll', handleScrollStart);
    window.addEventListener('scroll', debounce(handleScrollStop, 100)); // Using custom debounce function
  
    return () => {
      window.removeEventListener('scroll', handleScrollStart);
      window.removeEventListener('scroll', handleScrollStop);
    };
  }, []);
  

  const handleFrequencySelect = (frequency) => {
    setFrequency(frequency);
  };

  const formatAmount = (value) => {
    return (value / 100).toLocaleString(); // Assuming amount is in cents and converting to Rands for display
  };

  const getPaymentChannels = () => {
    return frequency === 'Eenmalig' ? ["qr", "card", "eft"] : ["card"];
  };

  const componentProps = { 
    publicKey,
    amount: amount,
    email,
    firstname: name,
    lastname,
    currency: "ZAR",
    metadata: {
      terms: termsAccept ? "yes" : "no",
      recurring: frequency
    },
    text: `Skenk R${formatAmount(amount)} ${frequency}`,
    callback_url,
    onSuccess: (transaction) => {
      if (frequency === 'Eenmalig') {
        setPaymentStatus('success');
      } else {
        saveTransaction(transaction.reference);
        setPaymentStatus('success');
      }
    },
    channels: getPaymentChannels() // Set channels based on frequency
  }

  const saveTransaction = async (reference_) => {
    const postData = {
      reference: reference_
    };

    try {
      const response = await fetch(serverURI, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(postData)
      });

      if (response.ok) {
        const data = await response.json();
        console.log('Transaction saved successfully:', data.message);
      } else {
        const errorData = await response.json();
        console.error('Failed to save transaction:', errorData.error);
      }
    } catch (error) {
      console.error('Network or parsing error while saving transaction:', error);
    }
  };

  return (
    <div className="donation-form">
      <img src={logo} alt="" className="logo-image" />
      <h1>Versterk StudentePlein</h1>
      <div className="frequency">
        {['Eenmalig', 'Maandeliks', 'Jaarliks'].map(freq => (
          <button
            key={freq}
            className={`frequency-btn ${frequency === freq ? 'selected' : ''}`}
            onClick={() => handleFrequencySelect(freq)}
          >
            {freq}
          </button>
        ))}
      </div>
      <div className="amounts">
        {[2500, 2000, 1500, 1000, 500, 100].map(amountValue => (
          <button
            key={amountValue}
            className={`amount ${amount === amountValue * 100 ? 'selected' : ''}`}
            onClick={() => handleAmountSelect(amountValue)}
            style={{ pointerEvents: isScrolling ? 'none' : 'auto' }}  // Disable pointer events when scrolling
          >
            R{amountValue}
          </button>
        ))}
        <div className="input-prefix">
          <input
            type="number"
            className="prefix"
            placeholder="Eie bedrag"
            value={inputValue}
            min="0"
            step="1"
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
          />
        </div>
      </div>
      <div className="options">
        <input type="text" required placeholder="Naam" onChange={(e) => setName(e.target.value)} />
        <input type="text" required placeholder="Van" onChange={(e) => setSurname(e.target.value)} />
        <input
          type="email"
          placeholder="E-posadres"
          value={email}
          onChange={handleEmailChange}
          required
        />
        {emailError && <div className="error-message">{emailError}</div>}
        <input type="checkbox" id="dedicate" checked={termsAccept} onChange={(e) => setTermsAccept(e.target.checked)} />
        <label htmlFor="dedicate" className="dedicate-label">Ek aanvaar StudentePlein se</label>
        <span className="dedicate" onClick={openPolicyModal}>Skenkingsbeleid</span>.
      </div>
      <PaystackButton
        {...componentProps}
        className={`next-btn ${!isFormValid ? 'disabled' : ''}`}
        onClick={(e) => {
          if (!isEmailValid) {
            e.preventDefault();
            alert('Please enter a valid email address.');
            return false;
          }
        }}
      />
      <div className="paystack-container">
        <img src={paystackLogos} alt="" className="paystack-logo" />
      </div>

       {/* Render the PolicyModal component if isPolicyModalOpen is true */}
       {isPolicyModalOpen && <PolicyModal onClose={closePolicyModal} />}
      
    </div>
  );
};

export default PaymentForm;
