import React from 'react';
import './PolicyModal.css';

const PolicyModal = ({ onClose }) => {

  const handleOverlayClick = (event) => {
    if (event.currentTarget === event.target) {
      onClose();
    }
  };
  
  return (
  <div className="modal-overlay" onClick={handleOverlayClick}>
  <div className="modal-content">
  <h2>SKENKINGSBELEID</h2>
  <h3>1. SKENKINGS</h3>
  <p>1.1 StudentePlein wend skenkings ter bevordering van sy verklaarde oogmerke in sy grondwet aan. Finansiële bestuur word kragtens StudentePlein se grondwet uitgeoefen, en StudentePlein se finansiële state word jaarliks deur ’n onafhanklike ouditeur hersien.</p>
  <p>1.2 Geen skenker wat ’n skenking op StudentePlein se aanlynplatforms maak is by magte om voor te skryf vir watter spesifieke oogmerk van StudentePlein sy of haar skenking aangewend moet word nie.</p>
  <p>1.3 Enige persoon kan op StudentePlein se aanlynskenkingsportaal (https://skenk.studenteplein.co.za) ’n aanbod maak om ’n skenking aan StudentePlein te maak deur die nodige vorm in te vul, waarby instemming tot hierdie beleid inbegrepe is, en ’n betaling deur middel van ’n toepaslike Paystack-betalingskanaal te maak.</p>
  <p>1.4 ’n Aanbod wat op die wyse in klousule 3.3 bepaal, gemaak word, word outomaties aanvaar sodra betaling suksesvol gemaak is, met voorbehoud dat StudentePlein die aanbod binne tien werksdae na ontvangs van die bedrag kan weier deur ‘n bewys aan die voornemende donateur te lewer dat die volle bedrag (minus enige toepaslike platformfooie) aan die voornemende skenker terugbetaal is.</p>
  <p>1.5 Met suksesvolle voltooiing van ‘n betaling op StudentePlein se aanlynskenkersportaal, sal ’n skenker ’n e-pos van Paystack ontvang met die onderwerp “Receipt from StudentePlein [transaksienommer]”.</p>
  <p>1.6 Indien ’n skenker ’n maandelikse of jaarlikse skenkingsopsie (“herhalende skenking”) gekies het sal die tersaaklike bedrag maandeliks of jaarliks, na gelang van geval, van ’n skenker se kredietkaart verhaal word.</p>
  <p>1.7 Behoudens die bepalings in hierdie paragraaf is ‘n skenking nie herroepbaar, kanselleerbaar of terugbetaalbaar nie.</p>
  <p>1.7.1 In die geval van herhalende skenkings kan die magtiging vir ‘n herhalende skenking met kennisgewing van ten minste tien (10) werksdae voor die volgende geskeduleerde betaling, beëindig word deurdat ‘n e-pos met ‘n ondubbelsinnige versoek vir beëindiging vergesel met die Paystack-transaksienommer aan admin@studenteplein.co.za gerig word.</p>
  <p>1.7.2 ’n Eenmalige skenking of ’n betaling as deel van ’n herhalende skenking wat in ’n afgelope dertig (30) dae betaal is kan op versoek van ‘n skenker en op die uitsluitlike goeddunke van StudentePlein se direksie aan ’n skenker terugbetaal word.</p>
  <p>1.7.3 Geen bedrag/e wat meer as dertig (30) dae gelede deur ’n skenker betaal is, sal terugbetaal kan word nie.</p>
  <p>1.8 In die geval van herhalende skenkings rus die verantwoordelikheid by ’n skenker om te verseker dat voldoende fondse beskikbaar telkens beskikbaar vir die volgende maandelikse of jaarlikse aftrekking. StudentePlein aanvaar geen aanspreeklikheid vir enige platform- of bankfooie weens geweierde transaksies nie.</p>
  <h3>2. DATA EN PRIVAATHEID</h3>
  <p>2.1 Terminologie in hierdie afdeling stem, waar toepaslik, ooreen met terminologie en die toepaslike definisies daarvoor in die Wet op Beskerming van Persoonlike Inligting, 4 van 2013 (“Wet”).</p>
  <p>2.2 "Paystack" verwys na Paystack South Africa (Edms) Bpk, ‘n derdepartydiensverskaffer wat betalings namens StudentePlein hanteer en ‘n PCI DSS Vlak 1 gesertifiseerde entiteit is. StudentePlein oefen geen beheer uit oor die wyse waarop Paystack Persoonlike Inligting van ‘n datasubjek verwerk en stoor nie, en aanvaar nie aanspreeklikheid daarvoor nie.</p>
<p>2.3 StudentePlein stoor ingevolge hierdie beleid hoofsaaklik twee kategorieë van Persoonlike Inligting:</p>
<ul>
  <li>2.3.1 Transaksie-inligting: Inligting wat ‘n datasubjek voorsien wanneer ‘n transaksie aangegaan word, soos aangevul deur inligting wat deur Paystack oor ‘n transaksie verskaf word.</li>
  <li>2.3.2 Registrasie-inligting: Inligting wat ‘n datasubjek voorsien wanneer 'n vorm ingevul word met persoonlike inligting soos naam, van en e-posadres.</li>
</ul>
<p>2.4 Die volgende Persoonlike Inligting word deur StudentePlein gestoor wanneer ‘n vorm op StudentePlein se skenkingsportaal ingevul word en ‘n suksesvolle transkasie gemaak word:</p>
<ul>
  <li>2.4.1 Naam en van</li>
  <li>2.4.2 E-posadres</li>
  <li>2.4.3 Datum van eerste betaling ontvang, in geval van herhalende skenkings</li>
  <li>2.4.4 Herhalende betalingsopsie</li>
  <li>2.4.5 Datum van laaste betaling, in geval van herhalende skenkings</li>
  <li>2.4.6 Paystack-verwante inligting oor ’n transaksie, in geval van herhalende skenkings</li>
  <li>2.4.7 Kaartbesonderhede in geënkripteerde formaat, in geval van herhalende skenkings</li>
</ul>
<p>2.5 In die geval van eenmalige skenkings stoor StudentePlein onder geen omstandighede skenkers se bank- of kaartbesonderhede nie.</p>
<p>2.6 In die geval van herhalende skenkings stoor StudentePlein slegs geselekteerde kaartbesonderhede insoverre dit nodig is om StudentePlein se wetlike- en finansiële bestuurverpligtinge na te kom. Die volgende kaartbesonderhede word gestoor:</p>
<ul>
  <li>2.6.1 Primêre rekeningnommer (PAN)</li>
  <li>2.6.2 Naam van kaarthouer</li>
  <li>2.6.3 Vervaldatum</li>
  <li>2.6.4 Kaarttipe</li>
  <li>2.6.5 Land waarin kaart uitgereik is</li>
  <li>2.6.6 Kaarthandelsmerk (soos Visa)</li>
  <li>2.6.7 Rekeninghouer</li>
</ul>
<p>2.7 Die CID/CAV2/CID/CVC2/CVV2 nommers (sekuriteitskode) van kaarthouers word onder geen omstandighede deur StudentePlein gestoor nie, nie eers in geënkripteerde formaat nie. Dit is egter gebruiklik vir derdepartyplatforms soos Paystack om sodanige inligting te stoor. StudentePlein oefen geen beheer uit oor die sekuriteitspraktyke van derdepartydiensverkaffers nie.</p>
<p>2.8 Wanneer StudentePlein kaartbesonderhede van skenkers stoor, soos in klousule 4.3 beskryf, doen StudentePlein dit ooreenkomstig toepaslike PCI SSC sekuriteitstandaarde. Dit beteken dat StudentePlein onder meer:</p>
<ul>
  <li>2.8.1 Alle kaartbesonderhede onleesbaar maak deur middel van ‘n AES-256-CBC-standaard enkriperingsalgoritme alvorens dit op ons sekure databasis gestoor word.</li>
  <li>2.8.2 Die enkripsiesleutel word aan behoorlike sleutelbestuurspraktyke onderwerp, soos om sleutels gereeld te skommel en dit veilig te berg.</li>
  <li>2.8.3 'n Unieke en lukrake inisiëringsvektor word vir elke enkripsiebewerking gebruik.</li>
  <li>2.8.4 Sterk toegangskontroles is geïmplementeer om te verseker dat slegs gemagtigde direksielede toegang tot die geënkripteerde data kan kry of dit kan dekripteer.</li>
  <li>2.8.5 Kaartbesonderhede, gestoor op die wyse hierbo beskryf, word uitgewis binne drie maande ná ’n skenker ’n magtiging vir ‘n herhalende skenking gestaak het.</li>
</ul>
<p>2.9 Persoonlike inligting word kragtens die voorskrifte van die Wet op Beskerming van Persoonlike Inligting, 4 van 2013, gestoor.</p>
<p>2.10 Deur ‘n vorm op StudentePlein se skenkingsportaal in te vul, stem ‘n datasubjek in tot die insameling van die inligting hierin vermeld.</p>
<p>2.11 ‘n Datasubjek gee toestemming dat StudentePlein inligting van ander betroubare bronne verkry om die inligting wat ‘n datasubjek verskaf het, of wat StudentePlein outomaties ingesamel het, op te dateer of aan te vul.</p>
<p>2.12 Behoudens die bepalings van hierdie beleid, sal StudentePlein nie ‘n datasubjek se persoonlike inligting met enige derde party deel nie, behalwe in omstandighede waar StudentePlein ingevolge ‘n hofbevel of die Wet verplig word om dit te doen.</p>
<p>2.13 Wanneer ondernemings namens StudentePlein dienste verrig word sodanige diensverskaffers wel met datasubjekte se persoonlike inligting voorsien maar verbied om die persoonlike inligting te gebruik vir ander doeleindes as wat deur StudentePlein versoek is en deur die Wet toegelaat word. ‘n Datasubjek wat ‘n skenking maak stem in dat sy of haar persoonlike inligting aan derdepartydiensverskaffers voorsien mag word om toepaslike funksies te verrig.</p>
<p>2.14 StudentePlein sal onder geen omstandighede ‘n datasubjek se bankkaartbesonderhede met enige derde party deel nie, behalwe in omstandighede waar StudentePlein ingevolge ‘n hofbevel of die Wet verplig word om dit te doen.</p>
<p>2.15 StudentePlein het veiligheidsmaatreëls geïmplementeer wat ontwerp is om persoonlike inligting teen ongemagtigde toegang, openbaarmaking, gebruik en wysiging te beskerm.</p>
<p>2.16 StudentePlein bied geen waarborg dat enige sekerheidsmaatreëls ondeurdringbaar of onfeilbaar is nie, en word gevrywaar van aanspreeklikheid vir enige skade wat moontlik gely mag word deur ‘n onvoorsiene lek van inligting deur ‘n ongemagtigde party.</p>
<p>2.17 StudentePlein is by magte om, behoudens die bepalings oor kaartbesonderhede, enige persoonlike inligting wat kragtens hierdie beleid verkry word te stoor en te behou vir ‘n tydperk van ten minste ses jaar ná ‘n datasubjek se laaste kommunikasie met StudentePlein, insluitend ‘n laaste skenking, wat ook al laaste gebeur.</p>
<p>2.18 StudentePlein is voorts gemagtig om ‘n datasubjek se persoonlike inligting vir ‘n langer tydperk te behou as wat hierbo bepaal word, mits sodanige behoud vir historiese-, statistieke of navorsingsdoeleindes is.</p>
<p>2.19 Indien ‘n tydperk van ses jaar waarna hierbo verwys word verstryk het en ‘n rekord van persoonlike inligting nie vir historiese-, statistieke of navorsingsdoeleindes behou word nie, sal StudentePlein die tersaaklike rekord uitwis of de-identifiseer soos deur die Wet vereis word.</p>
<p>2.20 Die regte van ‘n datasubjek kragtens die beleid stem ooreen met die regte wat aan datasubjekte verleen word in die Wet. ‘n Datasubjek het, behoudens die omvang daarvan soos in die Wet bepaal, in besonder die reg:</p>
<ul>
  <li>2.20.1 om toegang tot eie persoonlike inligting te verkry;</li>
  <li>2.20.2 op regstelling van eie persoonlike inligting;</li>
  <li>2.20.3 om verwerking van eie persoonlike inligting te laat beperk;</li>
  <li>2.20.4 om beswaar te maak teen verwerking van eie persoonlike inligting;</li>
  <li>2.20.5 op data-oordraagbaarheid;</li>
  <li>2.20.6 om by 'n toesighoudende owerheid te kla; en</li>
  <li>2.20.7 om toestemmings soos in die Wet vereis en wat voorheen kragtens die Wet en/of hierdie of ‘n ander beleid van StudentePlein verleen is, te onttrek.</li>
</ul>
<p>2.21 ‘n Datasubjek kan sy of haar regte kragtens die beleid uitoefen deur ‘n versoek aan admin@studenteplein.co.za te stuur.</p>
<h3>3. DOMICILIUM CITANDI ET EXECUTANDI</h3>
<p>3.1 StudentePlein se domicilium citandi et executandi is Victoriastraat 11, Lutz-gebou, Stellenbosch, 7600.</p>
<p>3.2 Enige geskille ten opsigte van aangeleenthede wat deur hierdie beleid en verbandhoudende wetgewing gereguleer word, ontstaan vir jurisdiksionele doeleindes by StudentePlein se gekose domicilium citandi et executandi.</p>
<h3>4. KONTAKBESONDERHEDE</h3>
<p>4.1 Fisiese- en posadres: Victoriastraat 11, Lutz-gebou, Stellenbosch, 7600.</p>
<p>4.2 Webblad: <a href="https://studenteplein.co.za">studenteplein.co.za</a></p>
<p>4.3 E-posadres: <a href="mailto:admin@studenteplein.co.za">admin@studenteplein.co.za</a></p>
<p>4.4 Inligtingsbeampte:</p>
<ul>
  <li>4.4.1 Naam en van: Frederik Rudolph van Dyk</li>
  <li>4.4.2 E-pos: <a href="mailto:frederik@studenteplein.co.za">frederik@studenteplein.co.za</a></li>
</ul>
<button className="close-button" onClick={onClose}>Maak toe</button> {/* Button at the bottom or top as preferred */}
</div>
    </div>
  );
};

export default PolicyModal;
