import './App.css';

const ThankYouComponent = ({ name, frequency, amount, onClose }) => {
    const formatAmount = (amount) => {
        return amount.toLocaleString();
    };

    return (
        <div className="thank-you-message">
            <div className="thank-you-content">
                {name}, baie dankie vir jou skenking van R{formatAmount(amount/100)}, {frequency.toLowerCase()}. Dit word op prys gestel.
            </div>
            <div>
            <button onClick={onClose}>Maak toe</button>
            </div>
        </div>
    );
};

export default ThankYouComponent;
