import './App.css';
import Parent from './Parent';


function App() {
  return (
    <div className="App">
<Parent />
      
    
    </div>
  );
}

export default App;

